import api from '@apiInstance';

export default {
  add (params) {
    return api.post('rfq_documents', params);
  },
  get (id) {
    return api.fetch(`rfq_documents/${id}`);
  },
  async list (params) {
    const response = await api.fetch('/rfq_documents', { params });
    return response;
  },
  update (params) {
    return api.patch('rfq_documents', params);
  },
  delete (id) {
    return api.remove('rfq_documents', id);
  }
};
